import styled from 'styled-components';

const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e3e3e3;
  border-radius: 15px;
  margin-right: .625rem;
  width: 6.25rem;
  min-width: 6.25rem;
  height: 6.25rem;
  background: #fff;
  color: ${props => { return props.theme.color.primaryColor }};
  padding: 0;

  &.active, &:hover {
    background: ${props => { return props.theme.color.primaryColor }};
    border: ${props => { return `2px solid ${props.theme.color.primaryColor}` }};
    color: #fff;
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    max-width: 100%;
    i {
      font-size: 2.5rem;
      margin-top: -10px;
    }
    p {
      font-size: ${props => `${14*props.theme.font.primaryFont.scaling/100}px`};
      line-height: 1.25rem;
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)};
      margin: 0;
    }
  }
`;

export {ButtonWrapper};
