import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext, WhiteLabelContext, WebappContext } from "ToolboxUtils/web/context/context";
import FacebookLoginButton from 'ToolboxComponents/commons/buttons/facebook/facebook';
import GoogleLoginButton from 'ToolboxComponents/commons/buttons/google/google';
import Button from 'ToolboxComponents/commons/buttons/button/button';
import Input from 'ToolboxComponents/commons/inputs/input/input';
import Warning from 'ToolboxComponents/commons/texts/warning/warning';
import sha1 from 'sha1';
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-signup/modal-signup.styles';

import { PASSWORD_MIN_LENGTH } from 'ToolboxParams/globals';

const ModalSignUp = props => {
  const theme = useContext(ThemeContext).state;
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordConfirmed, setConfirmedPassword] = useState();
  const [firstName, setFirstName] = useState();
  const [error, setErrorMessage] = useState(null);
  useEffect(() => {
    if (error) {
      setTimeout(function(){
        setErrorMessage(null);
      }, 8000);
    }
  }, [error]);

  const submit = e => {
    e.preventDefault();
    if (password !== passwordConfirmed) {
      setErrorMessage(whiteLabel.textualContent.modal.signUp.different);
    } else if (password.length < PASSWORD_MIN_LENGTH) {
      setErrorMessage(whiteLabel.textualContent.modal.signUp.missingCharacters);
    } else {
      analytics.sendInscriptionInteractionsEvent();
      props.signUp({email: email, firstName: firstName, password: sha1(password)})
    }
  }

  const modal = document.getElementById('signUpModal');
  const screen = document.getElementById('signUpScreen');

  if (screen) {
    screen.addEventListener('click', function(event) {
      if (modal) {
        let isClickInside = modal.contains(event.target);
        if (!isClickInside) {
          props.closeModal();
        }
      }
    });
  }

  return (
    <ModalWrapper theme={theme} isMobile={isMobile} id='signUpScreen'>
      <div className='modal-container' id='signUpModal'>
        <div className='header'>
          <h1 className='title'>
            <Text path={`modal.signUp.title`} />
          </h1>
          <button onClick={() => props.closeModal()} className='close' type='button'>
            <i className='icon adn adn-close' />
          </button>
        </div>
        <button onClick={() => props.closeModal()} className='close' type='button'>
          <i className='icon adn adn-close' />
        </button>
        <div className='container'>
          <form onSubmit={(e) => submit(e)}>
            <div className='fields'>
              <div id='email'>
                <Input
                  type='email'
                  placeHolder={whiteLabel.textualContent.modal.signUp.email}
                  picture='email'
                  onChange={(email) => setEmail(email)}
                  required={true}
                />
              </div>
              <div id='password'>
                <Input
                  type='password'
                  placeHolder={whiteLabel.textualContent.modal.signUp.password}
                  picture='lock'
                  onChange={(password) => setPassword(password)}
                  required={true}
                />
              </div>
              <div id='password2'>
                <Input
                  type='password'
                  placeHolder={whiteLabel.textualContent.modal.signUp.confirmation}
                  picture='lock'
                  onChange={(password) => setConfirmedPassword(password)}
                  required={true}
                />
              </div>
              <div id='firstName'>
                <Input
                  type='text'
                  placeHolder={whiteLabel.textualContent.modal.signUp.firstName}
                  picture='emoticon-outline'
                  onChange={(firstName) => setFirstName(firstName)}
                  required={true}
                />
              </div>
            </div>
            {error && <Warning error={error} picture='alert' />}
            {props.signUpErrorMessage && <Warning error={props.signUpErrorMessage} picture='alert' />}
            <div className='button'>
              <Button type='submit'>{whiteLabel.textualContent.modal.signUp.signUp}</Button>
            </div>
          </form>
          <div className='socials-buttons'>
            {whiteLabel && whiteLabel.config && whiteLabel.config.facebookId &&
              <FacebookLoginButton status='signup' textButton={whiteLabel.textualContent.modal.signUp.facebookSignup} onClick={() => {analytics.sendInscriptionInteractionsEvent();props.signUpWithFacebook()}} />
            }
            {props.isGoogleDisplayed &&
              <GoogleLoginButton status='signup' textButton={whiteLabel.textualContent.modal.signUp.googleSignup} onClick={() => {analytics.sendInscriptionInteractionsEvent();props.signUpWithGoogle()}} />
            }
          </div>
          <p className="text-separation space-top"></p>
          <div className='description'>
            <Text path={`modal.signUp.description`} />
          </div>
          <div className='button'>
            <Button type='button' onClick={() => props.openSignInModal()}>
              {whiteLabel.textualContent.modal.signUp.signIn}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

ModalSignUp.propTypes = {
  closeModal: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  signUpWithFacebook: PropTypes.func.isRequired,
  signUpWithGoogle: PropTypes.func.isRequired,
  openSignInModal: PropTypes.func.isRequired,
  signUpErrorMessage: PropTypes.string,
  isGoogleDisplayed: PropTypes.bool.isRequired,
};

export default ModalSignUp;
