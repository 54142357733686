import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import Button from 'ToolboxComponents/commons/buttons/button/button';
import ButtonLink from 'ToolboxComponents/commons/buttons/button-link/button-link';
import Input from 'ToolboxComponents/commons/inputs/input/input';
import Warning from 'ToolboxComponents/commons/texts/warning/warning';
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-forgotten-password/modal-forgotten-password.styles';

const ModalForgottenPassword = props => {
  const theme = useContext(ThemeContext).state;
  const whiteLabel = useContext(WhiteLabelContext).state;


  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [email, setEmail] = useState();

  const submit = e => {
    e.preventDefault();
    props.sendForgottenPasswordEmail({email: email})
  }

  const modal = document.getElementById('forgottenPasswordModal');
  const screen = document.getElementById('forgottenPasswordScreen');

  if (screen) {
    screen.addEventListener('click', function(event) {
      if (modal) {
        let isClickInside = modal.contains(event.target);
        if (!isClickInside) {
          props.closeModal();
        }
      }
    });
  }

  return (
    <ModalWrapper theme={theme} isMobile={isMobile} id='forgottenPasswordScreen'>
      <div className='modal-container' id='forgottenPasswordModal'>
        <div className='header'>
          <h1 className='title'>
            <Text path={`modal.forgottenPassword.reset`} />
          </h1>
          <button onClick={() => props.closeModal()} className='close' type='button'>
            <i className='icon adn adn-close' />
          </button>
        </div>
        <button onClick={() => props.closeModal()} className='close' type='button'>
          <i className='icon adn adn-close' />
        </button>
        <div className='container'>
          <div className='description'>
            <Text path={`modal.forgottenPassword.description`} />
          </div>
          {props.emailSent ?
            <div className='sent'>
              <Text path={`modal.forgottenPassword.sended`} data={{email: email}} />
            </div>
            : <form onSubmit={(e) => submit(e)}>
                <div className='fields'>
                  <div id='email'>
                    <Input
                      type='email'
                      placeHolder="E-mail"
                      picture='email'
                      onChange={(email) => setEmail(email)}
                      required={true}
                    />
                  </div>
                </div>
                {props.forgottenPasswordErrorMessage && <Warning error={props.forgottenPasswordErrorMessage} picture='alert' />}
                <div className='button'>
                  <Button type='submit'>{whiteLabel.textualContent.page.account.forgotReceiveMail}</Button>
                </div>
              </form>
          }
          <div className='forgotten'>
            <ButtonLink type='button' onClick={() => props.openSignInModal()}>{whiteLabel.textualContent.page.account.forgotGoBack}</ButtonLink>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

ModalForgottenPassword.propTypes = {
  closeModal: PropTypes.func.isRequired,
  sendForgottenPasswordEmail: PropTypes.func.isRequired,
  openSignInModal: PropTypes.func.isRequired,
  forgottenPasswordErrorMessage: PropTypes.string,
  emailSent: PropTypes.bool.isRequired,
};

export default ModalForgottenPassword;
