import styled from 'styled-components';

export const FirstWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const SecondWrapper = styled.div`
  width: 265px;
  height: 40px;
  background: #fff;
  color: #757575;
  border-radius: 1px;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  min-width: 0;
  font-family: Helvetica, Arial, sans-serif;
  margin: 10px 0 0 0;
  padding: 0 0 0 0;
`;

export const ThirdWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

export const FourthWrapper = styled.div`
  padding: 10px;
  float: left;
`;

export const FifthWrapper = styled.div`
  width: 18px;
`;

export const Title = styled.span`
  font-size: 14px;
  line-height: 38px;
  font-family: Roboto,arial,sans-serif;
  font-weight: 500;
  letter-spacing: .21px;
  margin-left: 6px;
  margin-right: 6px;
  vertical-align: top;
`;
