import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext, WhiteLabelContext } from "ToolboxUtils/web/context/context";
import FacebookLoginButton from 'ToolboxComponents/commons/buttons/facebook/facebook';
import GoogleLoginButton from 'ToolboxComponents/commons/buttons/google/google';
import Button from 'ToolboxComponents/commons/buttons/button/button';
import Warning from 'ToolboxComponents/commons/texts/warning/warning';
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-home/modal-home.styles';

const ModalHome = props => {
  const theme = useContext(ThemeContext).state;
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const modal = document.getElementById('homeModal');
  const screen = document.getElementById('homeScreen');

  if (screen) {
    screen.addEventListener('click', function(event) {
      if (modal) {
        let isClickInside = modal.contains(event.target);
        if (!isClickInside) {
          props.closeModal();
        }
      }
    });
  }
  return (
    <ModalWrapper theme={theme} isMobile={isMobile} id='homeScreen'>
      <div className='modal-container' id='homeModal'>
        <div className='header'>
          <h1 className='title'>
            <Text path='modal.home.title' />
          </h1>
          <button onClick={() => props.closeModal()} className='close' type='button'>
            <i className='icon adn adn-close' />
          </button>
        </div>
        <button onClick={() => props.closeModal()} className='close' type='button'>
          <i className='icon adn adn-close' />
        </button>
        <div className='container'>
          <div className='description'>
            {/* <Text path='modal.home.description' />
            <br />
            <br /> */}
            <div className='chuchoteurs'>
              <Text path='modal.home.chuchoteurs' />
              <a href='https://www.chuchoteurs.org'>chuchoteurs.org</a>
            </div>
          </div>
          <div className='description highlighted'>
            <Text path='modal.home.soon' />
          </div>
          <div className='inscription-buttons'>
            {props.homeErrorMessage && <Warning error={props.homeErrorMessage} picture='alert' />}
            {whiteLabel && whiteLabel.config && whiteLabel.config.facebookId &&
              <FacebookLoginButton status='signup' textButton={whiteLabel.textualContent.modal.home.facebookSignup} onClick={() => props.signUpWithFacebook()} />
            }
            {props.isGoogleDisplayed &&
              <GoogleLoginButton status='signup' textButton={whiteLabel.textualContent.modal.home.googleSignup} onClick={() => props.signUpWithGoogle()} />
            }
          </div>
          <p className="text-separation"><Text path='modal.home.or' /></p>
          <div className='button'>
            <Button type='button' icon='icon adn adn-email' reverseIconText={true} onClick={() => props.openSignUpModal()}>
              {whiteLabel.textualContent.modal.home.email}
            </Button>
          </div>
          <p className="text-separation space-top"></p>
          <div className='description'>
            <Text path='modal.home.signin' />
          </div>
          <div className='button'>
            <Button type='button' onClick={() => props.openSignInModal()}>
              {whiteLabel.textualContent.modal.home.signin2}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

ModalHome.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openSignUpModal: PropTypes.func.isRequired,
  openSignInModal: PropTypes.func.isRequired,
  signUpWithFacebook: PropTypes.func.isRequired,
  signUpWithGoogle: PropTypes.func.isRequired,
  isGoogleDisplayed: PropTypes.bool.isRequired,
  homeErrorMessage: PropTypes.string,
};

export default ModalHome;
