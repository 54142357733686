import React from 'react';
import PropTypes from 'prop-types';
import iconGg from 'ToolboxAssets/img/icons/google.svg';
import { FirstWrapper, SecondWrapper, ThirdWrapper, FourthWrapper, FifthWrapper, Title } from './google.styles';

const GoogleLoginButton = props => {
  return (
    <FirstWrapper onClick={() => props.onClick()}>
      <SecondWrapper>
        <ThirdWrapper>
          <FourthWrapper>
            <FifthWrapper>
              <img src={iconGg} alt="" />
            </FifthWrapper>
          </FourthWrapper>
          <Title>{props.textButton}</Title>
        </ThirdWrapper>
      </SecondWrapper>
    </FirstWrapper>
  )
};

GoogleLoginButton.propTypes = {
  textButton: PropTypes.string.isRequired,
}

export default GoogleLoginButton;
