import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  border: 1px solid #4a4a4a66;
  border-radius: 2px;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => { return props.theme.color.primaryColor }};
    height: 50px;
    width: 50px;
    font-size: 30px;
  }
  .field {
    width: 100%;
    border: ${props => (props.isMobile === true ? '' : '0')};
    ::placeholder {
      padding-left: 2px;
      color: #6c757d;
      font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)};
      font-style: italic;
    }
  }
`;
