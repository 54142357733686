import React, { useState, useEffect, useContext } from 'react';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import PropTypes from "prop-types";
import Slider from "ToolboxComponents/commons/sliders/slider/slider/slider";
import ProfileSlide from 'ToolboxComponents/commons/cards/profile-product-card/profile-product-card';
import Autosuggest from 'react-autosuggest';

const SliderProfiles = props => {
  const { primaryColor } = useContext(ThemeContext).state.color;
  const [profileSelected, setProfileSelected] = useState(props.defaultProfile);

  return (
    <Slider
      desktop={{
        slidesPerView: props.nbSlides ? props.nbSlides : 3.5,
        sideFade: true,
      }}
      mobile={{
        slidesPerView: props.nbSlides ? props.nbSlides : 3.5,
      }}
      dotsColorActive={primaryColor}
      slideTo={props.defaultProfile}
    >
      {props.profiles.map((profile, index) => (
        <ProfileSlide
          key={index}
          profile={profile}
          slidesWidth={props.slidesWidth}
          setProfileSelected={(profile) => {setProfileSelected(profile); props.onSelect(profile)}}
          profileSelected={profileSelected}
        />
      ))}
    </Slider>
    
  );
};

SliderProfiles.propTypes = {
  profiles: PropTypes.array.isRequired,
  defaultProfile: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
  nbSlides: PropTypes.number,
  slidesWidth: PropTypes.number,
};

export default SliderProfiles;
