import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import { AccountContext } from "ToolboxUtils/web/context/context";
import Button from 'ToolboxComponents/commons/buttons/button/button';
import ButtonLink from 'ToolboxComponents/commons/buttons/button-link/button-link';
import OptionGroup from 'ToolboxComponents/commons/buttons/option-group/option-group';
import SliderProfiles from 'ToolboxComponents/commons/sliders/slider-profiles/slider-profiles';
import { getNbSlidesAndWidth } from 'ToolboxUtils/web/slider-profiles/getNbSlidesAndWidth';
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-preferences/modal-preferences.styles';

const ModalPreferences = props => {
  const theme = useContext(ThemeContext).state;
  const [account] = useContext(AccountContext).usePath();

  const [newProfileSlideWidth, setProfileSlideWidth] = useState(null);
  const [nbProfileSlides, setProfileSlides] = useState(null);
  useEffect(() => {
    setTimeout(function(){
      let data = getNbSlidesAndWidth(props.profiles.length);
      setProfileSlides(data.nbProfileSlides);
      setProfileSlideWidth(data.newProfileSlideWidth);
    }, 100);
  // eslint-disable-next-line
  }, []);

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [transportId, setTransportId] = useState(null);
  const [profileId, setProfileId] = useState(null);

  const submit = e => {
    e.preventDefault();
    props.setPreferences({transportId: transportId ? transportId : null, profileId: profileId ? profileId : null})
  }

  const modal = document.getElementById('preferencesModal');
  const screen = document.getElementById('preferencesScreen');

  if (screen) {
    screen.addEventListener('click', function(event) {
      if (modal) {
        let isClickInside = modal.contains(event.target);
        if (!isClickInside) {
          props.closeModal();
        }
      }
    });
  }

  return (
    <ModalWrapper theme={theme} isMobile={isMobile} id='preferencesScreen'>
      <div className='modal-container' id='preferencesModal'>
        <div className='header'>
          <h1 className='title'>
            <Text path={`modal.preferences.welcome`} data={{firstName: account.firstName}} />
          </h1>
          <button onClick={() => props.closeModal()} className='close' type='button'>
            <i className='icon adn adn-close' />
          </button>
        </div>
        <button onClick={() => props.closeModal()} className='close' type='button'>
          <i className='icon adn adn-close' />
        </button>
        <div className='container'>
          <div className='description'>
            <Text path={`modal.preferences.description`} />
          </div>
          <form onSubmit={(e) => submit(e)}>
            <div className='option-group'>
              <label>
                <Text path={`modal.preferences.transport`} />
              </label>
              <OptionGroup
                buttons={props.transports}
                onSelect={id => setTransportId(id)}
              />
            </div>
            <div className='option-group slider-profiles'>
              <label>
                <Text path={`modal.preferences.profile`} />
              </label>
              {nbProfileSlides && newProfileSlideWidth &&
                <SliderProfiles
                  profiles={props.profiles}
                  nbSlides={nbProfileSlides}
                  slidesWidth={newProfileSlideWidth}
                  onSelect={id => setProfileId(id)}
                  defaultProfile={props.defaultProfile}
                />
              }
            </div>
            <div className='button'>
              <Button type='submit'>Valider</Button>
            </div>
          </form>
          <div className='button-link'>
            <ButtonLink type='button' onClick={() => props.closeModal()}>ou passer cette étape</ButtonLink>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

ModalPreferences.propTypes = {
  closeModal: PropTypes.func.isRequired,
  transports: PropTypes.array.isRequired,
  profiles: PropTypes.array.isRequired,
  setPreferences: PropTypes.func.isRequired,
};

export default ModalPreferences;
