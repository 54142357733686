import React from 'react';
import PropTypes from 'prop-types';
import { WarningWrapper } from 'ToolboxComponents/commons/texts/warning/warning.styles';

const Warning = props => {
  return (
    <WarningWrapper>
      <i className={`icon adn adn-${props.picture}`} />
      {props.error}
    </WarningWrapper>
  );
}

Warning.propTypes = {
  error: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
}

export default Warning;
