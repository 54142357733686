import React, { useContext } from 'react';
import {ThemeContext} from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import { ButtonWrapper } from './option-button.styles';

const OptionButton = props => {
  const theme = useContext(ThemeContext).state;
  return (
    <ButtonWrapper
      type="button"
      className={props.selected === props.button.id ? 'active' : ''}
      onClick={() => props.setSelected(props.button.id)}
      theme={theme}
    >
      <div className="content">
        <i className={`icon adn adn-${props.button.picture}`} />
        <p>{props.button.text}</p>
      </div>
    </ButtonWrapper>
  )
};

OptionButton.propTypes = {
  button: PropTypes.object.isRequired,
  selected: PropTypes.number,
  setSelected: PropTypes.func.isRequired,
}

export default OptionButton;
