import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: block;
  background: rgba(40, 40, 40, .9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30000000;
  overflow: auto;
  outline: 0;
  .modal-container {
    width: ${props => (props.isMobile === true ? '100%' : '')};
    min-height: ${props => (props.isMobile === true ? '100vh' : '')};
    max-width: ${props => (props.isMobile === true ? 'none' : '500px')};
    margin: ${props => (props.isMobile === true ? '0' : '1.75rem auto')};
    position: relative;
    background: white;
    padding-bottom: 1px;
    .header {
      display: flex;
      justify-content: center;
      flex-direction: ${props => (props.isMobile === true ? 'column-reverse' : 'row')};
      align-items: center;
      .title {
        padding: ${props => (props.isMobile === true ? '' : '24px 24px 20px 24px')};
        padding-bottom: 8px;
        color: ${props => { return props.theme.color.primaryColor }};
        font-size: ${props => `${16*props.theme.font.primaryFont.scaling/100}px`};
        font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
        font-style: italic;
        clear: both;
      }
      .close {
        display: ${props => (props.isMobile === true ? 'flex' : 'none')};
      }
    }
    .close {
      display: ${props => (props.isMobile === true ? 'none' : 'flex')};
      color: ${props => { return props.theme.color.primaryColor }};
      opacity: 1;
      position: ${props => (props.isMobile === true ? '' : 'absolute')};
      top: ${props => (props.isMobile === true ? '' : '.5rem')};
      right: ${props => (props.isMobile === true ? '' : '.5rem')};
    }
    .container {
      .description {
        text-align: center;
        font-size: ${props => `${14*props.theme.font.primaryFont.scaling/100}px`};
        margin-bottom: 2rem;
      }
      .sent {
        display: flex;
        justify-content: center;
        margin-bottom: 2rem;
        font-weight: ${props => parseInt(props.theme.font.primaryFont.weight)+300};
      }
      #email {
        margin-bottom: 15px;
      }
      .forgotten {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
      }
      .button {
        display: flex;
        justify-content: center;
        margin-bottom: 1.5rem;
      }
    }
  }
`;
