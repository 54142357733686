import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import Text from 'ToolboxComponents/commons/texts/text/text';
import { ThemeContext, WhiteLabelContext, WebappContext } from "ToolboxUtils/web/context/context";
import FacebookLoginButton from 'ToolboxComponents/commons/buttons/facebook/facebook';
import GoogleLoginButton from 'ToolboxComponents/commons/buttons/google/google';
import Button from 'ToolboxComponents/commons/buttons/button/button';
import ButtonLink from 'ToolboxComponents/commons/buttons/button-link/button-link';
import Input from 'ToolboxComponents/commons/inputs/input/input';
import Warning from 'ToolboxComponents/commons/texts/warning/warning';
import sha1 from 'sha1';
import { ModalWrapper } from 'ToolboxComponents/webapp/modals/modal-signin/modal-signin.styles';

const ModalSignIn = props => {
  const theme = useContext(ThemeContext).state;
  const [analytics] = useContext(WebappContext).usePath('analytics');
  const [whiteLabel] = useContext(WhiteLabelContext).usePath();

  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const submit = e => {
    e.preventDefault();
    analytics.sendConnexionInteractionsEvent();
    props.signIn({email: email, password: sha1(password)});
  }

  const modal = document.getElementById('signInModal');
  const screen = document.getElementById('signInScreen');

  if (screen) {
    screen.addEventListener('click', function(event) {
      if (modal) {
        let isClickInside = modal.contains(event.target);
        if (!isClickInside) {
          props.closeModal();
        }
      }
    });
  }

  return (
    <ModalWrapper theme={theme} isMobile={isMobile} id='signInScreen'>
      <div className='modal-container' id='signInModal'>
        <div className='header'>
          <h1 className='title'>
            <Text path={`modal.signIn.title`} />
          </h1>
          <button onClick={() => props.closeModal()} className='close' type='button'>
            <i className='icon adn adn-close' />
          </button>
        </div>
        <button onClick={() => props.closeModal()} className='close' type='button'>
          <i className='icon adn adn-close' />
        </button>
        <div className='container'>
          {props.alternativeText === 'fav' &&
            <div className='description'>
              <Text path='modal.signIn.fav' />
            </div>
          }
          {props.alternativeText === 'secret' &&
            <div className='description'>
              <Text path={`modal.signIn.secret`} />
            </div>
          }
          {props.alternativeText === 'favorites' &&
            <div className='description'>
              <Text path={`modal.signIn.favorites`} />
            </div>
          }
          {props.alternativeText === null &&
            <div className='description'>
              <Text path={`modal.signIn.description`} />
            </div>
          }
          <form onSubmit={(e) => submit(e)}>
            <div className='fields'>
              <div id='email'>
                <Input
                  type='email'
                  placeHolder={whiteLabel.textualContent.modal.signIn.email}
                  picture='email'
                  onChange={(email) => setEmail(email)}
                  required={true}
                />
              </div>
              <div id='password'>
                <Input
                  type='password'
                  placeHolder={whiteLabel.textualContent.modal.signIn.password}
                  picture='lock'
                  onChange={(password) => setPassword(password)}
                  required={true}
                />
              </div>
            </div>
            {props.signInErrorMessage && <Warning error={props.signInErrorMessage} picture='alert' />}
            <div className='button'>
              <Button type='submit'>{whiteLabel.textualContent.modal.signIn.signIn}</Button>
            </div>
          </form>
          <div className='forgotten'>
            <ButtonLink type='button' onClick={() => props.openForgottenPasswordModal()}>{whiteLabel.textualContent.modal.signIn.forgottenPassword}</ButtonLink>
          </div>
          <p className="text-separation"><Text path={"modals.signIn.or"} /></p>
          <div className='socials-buttons'>
            {whiteLabel && whiteLabel.config && whiteLabel.config.facebookId &&
              <FacebookLoginButton status='signup' textButton={whiteLabel.textualContent.modal.signIn.facebookSignin} onClick={() => {analytics.sendConnexionInteractionsEvent(); props.signInWithFacebook()}} />
            }
            {props.isGoogleDisplayed &&
              <GoogleLoginButton status='signup' textButton={whiteLabel.textualContent.modal.signIn.googleSignin} onClick={() => {analytics.sendConnexionInteractionsEvent(); props.signInWithGoogle()}} />
            }
          </div>
          <p className="text-separation space-top"></p>
          <div className='description'>
            <Text path={`modal.signIn.signUp`} />
          </div>
          <div className='button'>
            <Button type='button' onClick={() => props.openSignUpModal()}>
              {whiteLabel.textualContent.modal.signIn.signUp2}
            </Button>
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
}

ModalSignIn.propTypes = {
  closeModal: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  openForgottenPasswordModal: PropTypes.func.isRequired,
  signInWithFacebook: PropTypes.func.isRequired,
  signInWithGoogle: PropTypes.func.isRequired,
  openSignUpModal: PropTypes.func.isRequired,
  signInErrorMessage: PropTypes.string,
  alternativeText: PropTypes.string,
  isGoogleDisplayed: PropTypes.bool.isRequired,
};

export default ModalSignIn;
