import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import isMobileView from 'ToolboxUtils/web/is-mobile-view';
import {ThemeContext} from "ToolboxUtils/web/context/context";
import { InputWrapper } from 'ToolboxComponents/commons/inputs/input/input.styles';

const Input = props => {
  const theme = useContext(ThemeContext).state;
  const [isMobile, setIsMobile] = useState(null);
  useEffect(() => {
    setIsMobile(isMobileView());
  }, [isMobile]);
  return (
    <InputWrapper theme={theme} isMobileView={isMobileView}>
      <div className='icon'>
        <i className={`icon adn adn-${props.picture}`} />
      </div>
      <input type={props.type} placeholder={props.placeHolder} className='field' onChange={(e) => props.onChange(e.target.value)} required={props.required} />
    </InputWrapper>
  );
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  placeHolder: PropTypes.string,
};

export default Input;
