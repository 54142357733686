import React, { useContext } from 'react';
import { ThemeContext } from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import { ProfileWrapper } from './profile-product-card.styles';

const ProfileSlide = props => {
  const theme = useContext(ThemeContext).state;
  return (
    <ProfileWrapper
      width={props.slidesWidth}
      theme={theme}
      onClick={() => props.setProfileSelected(props.profile.id)}
      className={props.profileSelected === props.profile.id ? 'active' : ''}
    >
      <div className="content">
        <i className={`icon adn adn-${props.profile.picture}`} />
        <p>{props.profile.name}</p>
      </div>
    </ProfileWrapper>
  );
};

ProfileSlide.propTypes = {
  profile: PropTypes.object,
};

export default ProfileSlide;
