import styled from 'styled-components';

export const FirstWrapper = styled.div`
  line-height: 1.28;
  width: 265px;
  height: 40px;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  text-align: justify;
`;

export const SecondWrapper = styled.div`
  border-radius: 4px;
  cursor: pointer;
  vertical-align: top;
`;

export const Table = styled.table`
  width: 100%;
  background-color: #4267b2;
  border-radius: 4px;
  color: #fff;
`;

export const FirstCell = styled.td`
  width: 40px;
  padding: 0;
`;

export const SvgWrapper = styled.span`
  height: 24px;
  margin: 8px;
  float: left;
`;

export const Svg = styled.svg`
  height: 24px;
  width: 24px;
`;

export const SecondCell = styled.td`
  padding: 0;
`;

export const Title = styled.div`
  margin: 0 24px 0 12px;
  letter-spacing: .25px;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 14px;
`;
