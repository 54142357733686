import React, { useState, useEffect, useContext } from 'react';
import {ThemeContext} from "ToolboxUtils/web/context/context";
import PropTypes from 'prop-types';
import { GroupWrapper } from './option-group.styles';
import OptionButton from 'ToolboxComponents/commons/buttons/option-button/option-button';

let defaultSelected = false;

const OptionGroup = props => {
  const theme = useContext(ThemeContext).state;
  const [selected, setSelected] = useState(props.defaultOption);
  useEffect(() => {
    if (selected && defaultSelected) {
      props.onSelect(selected);
    }
    if (props.defaultOption === selected && !defaultSelected) {
      defaultSelected = true;
    }
  }, [selected])
  return (
    <GroupWrapper theme={theme}>
      {props.buttons.map((button, index) => (
        <OptionButton
          key={index}
          button={button}
          setSelected={(id) => setSelected(id)}
          selected={selected}
        />
      ))}
    </GroupWrapper>
  )
};

OptionGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  defaultOption: PropTypes.number,
}

export default OptionGroup;
